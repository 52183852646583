.keywords-datagrid-row {
    min-height: auto!important;
    max-height: none!important;
    padding-top: 16px;
}

.keywords-datagrid-row > div {
    min-height: auto!important;
    max-height: none!important;
    white-space: normal!important;
    padding-bottom: 16px!important;
}

::file-selector-button {
    display: none;
}
